@mixin avatar-radius {
  border-radius: 4px;
  background: transparent no-repeat;
  background-position: 50%;
  background-clip: padding-box;
}

@mixin avatar-size($size: 48px) {
  width: $size;
  height: $size;
  background-size: $size $size;
}

@mixin search-input {
  outline: 0;
  box-sizing: border-box;
  width: 100%;
  border: 0;
  box-shadow: none;
  font-family: inherit;
  background: $ui-base-color;
  color: $darker-text-color;
  font-size: 14px;
  margin: 0;

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }

  &:focus {
    background: lighten($ui-base-color, 4%);
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

@mixin search-popout {
  background: $simple-background-color;
  border-radius: 4px;
  padding: 10px 14px;
  padding-bottom: 14px;
  margin-top: 10px;
  color: $light-text-color;
  box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);

  h4 {
    text-transform: uppercase;
    color: $light-text-color;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  li {
    padding: 4px 0;
  }

  ul {
    margin-bottom: 10px;
  }

  em {
    font-weight: 500;
    color: $inverted-text-color;
  }
}
